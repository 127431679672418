











import { defineComponent, computed, toRefs } from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import { useSnackbar } from '~/compositions/snackbar'
import { copyTextToClipboard } from '~/utils/dom'
import AccountService from '~/services/account/AccountService'
import { useUserAgent } from '~/compositions/user-agent'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  components: {
    CImg
  },
  props: {
    qrCode: { type: String, required: true },
    uri: { type: String, required: true },
    dummy: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n()
    const { isMobile } = useUserAgent()
    const accountService = useDep(AccountService)
    const snackbar = useSnackbar()
    const { uri, qrCode, dummy } = toRefs(props)

    const authenticatorLink = computed(() =>
      accountService.getAuthenticatorLink()
    )

    const qrCodeImage = computed(() => {
      if (dummy.value) {
        return require('~/assets/img/2fa/blurred-qr-code.png')
      }

      return qrCode.value
        ? `data:image/jpeg;charset=utf-8;base64,${qrCode.value}`
        : ''
    })

    function onQrCodeClick() {
      if (dummy.value) {
        return
      }

      if (isMobile.value) {
        window.location.href = uri.value

        return
      }

      const url = new URL(uri.value)
      const secret = url.searchParams.get('secret') || ''
      copyTextToClipboard(secret)

      snackbar.success(t('code_was_copied'))
    }

    return {
      qrCodeImage,
      authenticatorLink,
      onQrCodeClick,
      isMobile
    }
  }
})
