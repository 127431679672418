























import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch,
  vue3Model,
  vue3Set
} from '~/utils/nuxt3-migration'
import { Key } from '~/constants/keyboard'

type Cell = {
  key: number
  value: string
}

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      default: 6
    },
    autofocus: {
      type: Boolean,
      default: true
    },
    secure: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { autofocus, secure, length } = toRefs(props)
    const focusedCellIdx = ref(0)
    const cells = ref<Cell[]>([])
    const pinTemplateRefs = ref<HTMLElement[]>([])

    const pinCode = computed(() => {
      return cells.value.reduce((pin, cell) => pin + cell.value, '')
    })

    watch(
      () => props.modelValue,
      val => {
        if (val) {
          onParentValueUpdated()
        } else {
          reset()
        }
      }
    )

    watch(length, () => reset())
    watch(pinCode, val => emit('update:modelValue', val))

    onMounted(() => {
      init()
      onParentValueUpdated()

      if (autofocus.value) {
        nextTick(() => focusCellByIndex())
      }
    })

    function init(): void {
      for (let key = 0; key < length.value; key += 1) {
        setCellObject(key)
      }
    }

    function setCellObject(key: number): void {
      vue3Set(cells.value, key, { key, value: '' })
    }

    function onParentValueUpdated(): void {
      if (
        props.modelValue.length !== length.value &&
        props.modelValue.length <= length.value
      ) {
        emit('update:modelValue', pinCode.value)
        return
      }

      props.modelValue.split('').forEach((cell: string, idx: number) => {
        cells.value[idx].value = cell || ''
      })
    }

    function onCellChanged(index: number, newVal: string): void {
      const currentInputValue = cells.value[index]?.value

      if (isLengthValid(newVal)) {
        newVal.split('').forEach((cell: string, idx: number) => {
          cells.value[idx].value = cell || ''
        })

        return
      }

      if (
        currentInputValue.length > length.value + 1 &&
        currentInputValue.match(/^\d+$/)
      ) {
        newVal
          .substring(0, length.value)
          .split('')
          .forEach((cell: string, idx: number) => {
            cells.value[idx].value = cell || ''
          })
        return
      }

      if (!isTheCellValid(newVal, false)) {
        cells.value[index].value = ''
        return
      }
      focusNextCell()
    }

    function onCellErase(index: number, e: Event): void {
      const isThisCellFilled = cells.value[index].value.length

      if (!isThisCellFilled) {
        focusPreviousCell()
        e.preventDefault()
      }
    }

    function onKeyDown(e: KeyboardEvent): void {
      switch (e.code) {
        case Key.ARROW_LEFT:
          focusPreviousCell()
          break

        case Key.ARROW_RIGHT:
          focusNextCell()
          break

        default:
          break
      }
    }

    function reset(): void {
      init()
      focusCellByIndex()
    }

    /* helpers */
    function isTheCellValid(cell: string, allowEmpty = true): boolean {
      if (!cell) {
        return allowEmpty ? cell === '' : false
      }
      return !!cell.match('^\\d{1}$')
    }

    function isLengthValid(val: string) {
      return !!val.match(RegExp(`^\\d{${length.value}}$`))
    }

    function focusPreviousCell(): void {
      if (!focusedCellIdx.value) return

      focusCellByIndex(focusedCellIdx.value - 1)
    }

    function focusNextCell(): void {
      if (focusedCellIdx.value === length.value - 1) return

      focusCellByIndex(focusedCellIdx.value + 1)
    }

    function focusCellByIndex(index: number = 0): void {
      const el = pinTemplateRefs.value?.[index]
      if (el) {
        el!.focus()
        el!.select()
      }

      focusedCellIdx.value = index
    }

    const inputType = computed(() => (secure.value ? 'password' : 'tel'))

    return {
      inputType,
      cells,
      focusedCellIdx,
      onCellErase,
      onKeyDown,
      pinTemplateRefs,
      onCellChanged,
      focusCellByIndex
    }
  }
})
