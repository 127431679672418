import { CIcon } from '~/icons/types'
export const ciCar: CIcon = {
  width: 929,
  height: 290,
  paths: [
    {
      d:
        'M290.98 9.12c17.82-3.43 35.78-6.37 53.89-7.75 8.55-.66 17.24-.96 25.7.78-2.53 10.37-5.57 20.61-7.91 31.03-4.8.68-9.71-.36-14.44.49-31.73 2.98-63.19 8.58-94.21 15.86-46.16 11.01-91.91 24.93-134.73 45.62-21.74 10.83-43.18 23.36-60.1 41.05-10.51 11.07-18.99 25.15-19.64 40.76-.87 11.53 4.59 22.84 13.26 30.27 10.18 9.45 24.37 13.81 38.12 12.98 10.53-.26 20.48-4.29 29.82-8.85 2.83 3.65 3.43 8.39 4.66 12.72.88 4.52 2.49 9.14 1.95 13.79-2.85 3.13-7.32 3.95-11.19 5.2-8.59 2.23-17.35 3.83-26.16 4.92-20.04 1.72-40.89-2.89-57.59-14.32-11.6-7.97-21.73-18.69-27.09-31.85-5.38-13.18-5.71-28.19-1.47-41.73 4.47-14.59 13.55-27.31 24.02-38.22 16.02-16.57 35.32-29.56 55.25-40.94 41.19-23.08 85.75-39.46 130.89-52.94 25.36-7.42 51.03-13.82 76.97-18.87Z',
      fill: '#fff'
    },
    {
      d:
        'M208.03 128.92c18.69-4.13 38.99-4.53 56.83 3.22 6.68 2.77 12.52 7.17 17.69 12.17 1.04-5.16 1.86-10.36 2.95-15.51 13.28.13 26.57.01 39.85.06-7.12 36.93-14.2 73.87-21.36 110.79-13.15-.02-26.29-.01-39.44-.01.75-3.95 1.53-7.9 2.24-11.86-5.17 2.82-10.2 5.92-15.66 8.19-11.92 5.27-25.14 6.85-38.07 6.41-15.83-.3-32.12-4.13-44.99-13.7-10.39-7.76-19.12-18.71-21.42-31.74-2.72-13.18 1.58-27.02 9.78-37.46 12.42-16.44 31.9-25.98 51.6-30.56m18.46 22.64c-12.01 1.6-23.85 6.69-32.29 15.54-7.47 7.73-10.53 19.48-7.63 29.84 2.12 7.19 7.91 12.7 14.43 16.06 10.69 5.62 23.25 6.2 35.04 4.89 12.12-1.28 24.22-6.3 32.28-15.69 6.8-7.49 11.31-18.03 8.72-28.21-1.71-8.29-8.38-14.61-15.76-18.17-10.76-5.17-23.13-5.62-34.79-4.26Z',
      fill: '#fff'
    },
    {
      d:
        'M400.57 136.61c11.54-9.11 27.63-13.26 41.86-8.7-4.73 8.13-9.53 16.23-14.29 24.35-8.41-2.22-17.62.04-24.25 5.59-9.49 7.49-12.63 19.88-15.56 31.03-3.58 16.82-6.61 33.75-9.67 50.67-13.39.22-26.8.01-40.19.11 7.13-36.93 14.17-73.88 21.37-110.8 11.84-.01 23.67-.01 35.5 0-.77 4.65-1.89 9.24-2.44 13.93 2.98-1.49 5-4.25 7.67-6.18Z',
      fill: '#fff'
    },
    {
      d:
        'M604.92 134.17c16.49-2.51 34.05-2.48 49.61 4.22 6.07 2.52 11.4 6.39 16.77 10.09.98-4.3 1.7-8.65 2.63-12.97 13.3-.04 26.61.07 39.91-.05-5.46 26.85-10.85 53.72-16.28 80.58-2.42 11.79-4.55 23.71-8.69 35.05a38.135 38.135 0 0 1-9.71 15.11c-9.47 9.18-21.8 14.92-34.35 18.59-15.18 4.18-31.06 5.16-46.74 4.75-16.99-.46-34.16-3.52-49.52-11.02-6.76-3.28-13.18-7.5-18.24-13.12 11.64-3.49 22.93-8.07 34.45-11.95 13.88 9.79 31.63 12.06 48.2 10.79 11.1-.83 22.57-3.73 31.38-10.84 6.2-4.95 9.1-12.75 10.64-20.3-19.69 11.64-43.46 15.42-65.94 11.85-16.11-2.65-32.35-9.23-43.28-21.78-7.31-8.19-11.47-19.17-10.9-30.18.44-14.44 8.9-27.37 19.56-36.59 14.2-12.12 32.24-19.09 50.5-22.23m11.49 24.49c-12.64 1.37-25.16 6.5-34.12 15.7-5.56 5.71-8.8 13.75-8.09 21.76.33 7.07 4.55 13.51 10.31 17.42 9.09 6.41 20.54 8.04 31.41 8.08 13.3-.26 27.13-3.4 37.82-11.69 7.84-6.13 13.87-15.63 13.18-25.9-.13-7.51-5.07-14.06-11.18-18.02-11.41-7.78-25.97-8.81-39.33-7.35Z',
      fill: '#fff'
    },
    {
      d:
        'M789.06 143.2c11.56-9.21 27.62-13.09 41.9-8.77-4.76 8.17-9.63 16.28-14.33 24.49-8.14-2.22-17.11-.2-23.66 5.05-9.38 6.93-12.75 18.8-15.67 29.52-4 17.44-6.79 35.14-10.29 52.69-13.35.02-26.71.01-40.07 0 7.1-36.89 14.26-73.78 21.3-110.68 11.86 0 23.73.07 35.6-.04-.87 4.77-1.9 9.52-2.59 14.32 2.9-1.84 5.05-4.57 7.81-6.58Z',
      fill: '#fff'
    },
    {
      d:
        'M460.57 209.73c7.88-1.95 16.57-1.71 24 1.78 4.02 1.95 7.91 4.83 9.78 9.02 1.96 4.46.97 9.96-2.37 13.49-5.56 5.99-13.99 8.6-22.01 8.41-7.95.12-16.33-2.53-21.72-8.61-3.5-3.82-4.28-9.86-1.66-14.36 2.95-5.1 8.47-8.16 13.98-9.73Z',
      fill: '#fff'
    },
    {
      d:
        'M370.57 2.15c16.53 3.28 31.67 11.07 46.56 18.66 23.78 12.38 47.32 25.58 72.82 34.18 14.88 5.09 30.35 8.6 46.04 9.87 12.65.91 25.34.54 38.01.73 55.04.49 110.12 2.36 164.97 7.27 23.75 2.22 47.46 5.01 70.99 8.99 23.61 4.11 47.21 9.11 69.74 17.43 12.63 4.82 25.22 10.55 35.52 19.47 7.09 6.13 12.99 14.6 13.25 24.26.47 10.71-5.15 20.53-11.76 28.53-11.67 13.76-26.74 24.14-42.24 33.13-18.2 10.33-37.52 18.75-57.6 24.68-10.38 2.95-21.04 5.49-31.89 5.41 3.01-2.8 6.9-4.3 10.47-6.23 14.85-7.39 29.76-14.68 44.23-22.78 16.89-9.54 33.74-19.74 47.65-33.41 6.48-6.66 12.83-14.71 13.37-24.37.35-9.04-6.19-16.48-13.24-21.28-12.41-8.42-26.94-12.95-41.28-16.8-23.66-6.16-47.91-9.67-72.15-12.56-66.39-7.4-133.26-9.14-200.01-9.91-13.66-.23-27.37.39-40.97-1.22-42.38-4.65-81.31-23.14-120.46-38.74-19.31-7.33-39.45-15.31-60.5-13.59-1.31.15-2.6.09-3.87-.2 4.73-.85 9.64.19 14.44-.49 2.34-10.42 5.38-20.66 7.91-31.03Z',
      fill: '#73c8e8'
    }
  ],
  name: 'car',
  type: 'brand'
}
