





















































































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  useRoute,
  useRouter,
  nextTick,
  onBeforeUnmount,
  toRefs
} from '~/utils/nuxt3-migration'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { ciPlot } from '~/icons/source/brand/plot'
import GoogleLoginButton from '~/components/shared/social-login/GoogleLoginButton.vue'
import FacebookLoginButton from '~/components/shared/social-login/FacebookLoginButton.vue'
import AppleLoginButton from '~/components/shared/social-login/AppleLoginButton.vue'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import UserAuthService from '~/services/login/UserAuthService'
import PasswordFormGroup from '~/pages/shared/login_register/PasswordFormGroup.vue'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { ciCar } from '~/icons/source/brand/car'
import CInfoTooltip from '~/components/shared/configurable/info/CInfoTooltip.vue'
import { useI18n } from '~/compositions/i18n'
import { useDep } from '~/compositions/dependency-container'
import { useLogger } from '~/compositions/logger'
import { AxiosError } from 'axios'
import { HttpStatus } from '~/constants/http'
import { useSnackbar } from '~/compositions/snackbar'
import CPin from '~/components/shared/configurable/form/input/CPin.vue'
import CQRCode from '~/components/shared/configurable/CQRCode.vue'
import { ClientFlashMessageType } from '~/models/flash/types'
import FlashService from '~/services/flash/FlashService'
import { defineComponentTranslations } from '~/utils/i18n'
import { onMounted } from '@vue/composition-api'
import { useCookies } from '~/compositions/useCookies'

const TWO_FA_STATUS = {
  FORGOT_2FA: 'forgot2FA',
  SUBMIT_PIN: 'submitPin'
}

export default defineComponent({
  components: {
    CInfoTooltip,
    PasswordFormGroup,
    FacebookLoginButton,
    GoogleLoginButton,
    AppleLoginButton,
    CFormError,
    CImg,
    CQRCode,
    CPin
  },
  props: {
    redirectAfterLogin: { type: Boolean, default: true }
  },
  setup(props, { emit }) {
    const { redirectAfterLogin } = toRefs(props)
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const logger = useLogger()
    const cookies = useCookies()
    const authService = useDep(UserAuthService)
    const flashService = useDep(FlashService)
    const snackbar = useSnackbar()

    const loginFormRef = ref()
    const passwordRef = ref()

    const loading = ref(false)
    const username = ref(route.value.query.username || '')
    const password = ref('')
    const pin = ref('')

    const twoFA = ref('')
    const error = ref('')
    const invalidUsername = ref('')
    const invalidPass = ref('')
    const invalidTwoFA = ref('')
    const invalidPin = ref('')
    const usFocused = ref(false)
    const isTwoFAInputVisible = ref(false)
    const isPinVisible = ref(false)
    const qrCode = ref('')
    const uri = ref('')
    const countdown = ref()
    const isResendVisible = ref(false)
    const pinTemplateRef = ref(null)

    onBeforeUnmount(() => {
      clearTimeout(countdown.value)
    })

    onMounted(() => {
      if (route.value.query.username && passwordRef.value) {
        // focus password input
        passwordRef.value.focus()
      }
    })

    const icons = computed(() => ({
      plot: ciPlot,
      car: ciCar,
      user: faUser
    }))

    const mainIcon = computed(() => ciCar)
    const title = computed(() =>
      inAudits.value ? t('sign in for audits') : t('sign in to car.gr')
    )

    const inAudits = computed(() => {
      return route.value.name?.startsWith('__audits')
    })

    const showMustLoginAlert = computed(() => {
      const { query } = route.value
      return query.gotonext && query['must-login'] === '1'
    })

    const showDealerWarning = computed(() => {
      const { query } = route.value
      return query.gotonext && query.relatedDealer === '1'
    })

    const passwordResetUrl = computed(() => {
      return router.resolve({
        name: inAudits.value ? '__audits_password_reset' : '__password_reset'
      }).href
    })

    const formState = computed(() => {
      if (
        Boolean(error.value) &&
        username.value === invalidUsername.value &&
        password.value === invalidPass.value
      ) {
        return false
      }

      return null
    })

    const siteName = computed(() => {
      return 'Car.gr'
    })

    function onSuccess() {
      cookies.clearSessionCookies()
      emit('success-login')
    }

    async function login(twoFaStatus?: string) {
      clearTimeout(countdown.value)
      isResendVisible.value = false
      const isForgot2FA = TWO_FA_STATUS.FORGOT_2FA === twoFaStatus
      if (!loginFormRef.value.$el.checkValidity() && !isForgot2FA) {
        // Use form validation
        loginFormRef.value.$el.requestSubmit()
        return
      }
      passwordRef.value.resetShowPass()
      await nextTick()
      error.value = ''
      invalidUsername.value = ''
      invalidPass.value = ''
      loading.value = true

      try {
        const {
          redirectUrl,
          qr,
          uri: uriRes,
          smsSent
        } = await authService.login(
          username.value,
          password.value,
          twoFA.value,
          isForgot2FA,
          pin.value
        )
        uri.value = uriRes || ''

        // First time login with 2fa. Show qr image
        if (qr && !pin.value) {
          qrCode.value = qr
          isTwoFAInputVisible.value = true
          loading.value = false
          // Forgot 2FA
        } else if (twoFaStatus === TWO_FA_STATUS.FORGOT_2FA) {
          loading.value = false
          isTwoFAInputVisible.value = false
          isPinVisible.value = true
          if (pinTemplateRef.value) {
            pinTemplateRef.value.focusCellByIndex()
          }
          snackbar.success(t('sms_pin_2fa_retrieve'), {
            time: 7000
          })
          pin.value = ''
          twoFA.value = ''
          runCountdown()
          // Send pin
        } else if (pin.value) {
          pin.value = ''
          isTwoFAInputVisible.value = true
          isPinVisible.value = false
          loading.value = false
          qrCode.value = qr
        } else if (smsSent) {
          twoFA.value = ''
        } else if (redirectAfterLogin.value) {
          onSuccess()
          window.location = redirectUrl || '/'
        } else {
          onSuccess()
        }
      } catch (error) {
        handleLoginError(error as AxiosError)
      }

      function handleLoginError(e: AxiosError) {
        loading.value = false
        const errorData = e?.response?.data
        const { error: errorMessage, errors, status, message } = errorData
        if (!errorData) {
          logger.captureError(e)
          return
        }
        if (status === HttpStatus.UNPROCESSABLE_ENTITY) {
          if (isTwoFAInputVisible.value && errors?.one_time_password) {
            invalidTwoFA.value = errors.one_time_password
          } else if (errors?.one_time_password) {
            isTwoFAInputVisible.value = true
          } else if (errors.retrieve_qr_pin) {
            runCountdown()
            invalidPin.value = errors.retrieve_qr_pin
          }
          // Handling for users that are already logged-in on another tab
        } else if (status === HttpStatus.UNAUTHORIZED) {
          flashService.setClientFlashCookie(
            ClientFlashMessageType.ALREADY_LOGGED_IN,
            {
              showAsSnackbar: true,
              snackbarVariant: 'error',
              text: errorMessage || message
            }
          )

          window.location.href = '/'
          return
        }

        invalidUsername.value = username.value
        invalidPass.value = password.value
        error.value = errorMessage || message
      }
    }

    function runCountdown() {
      countdown.value = setTimeout(() => {
        isResendVisible.value = true
      }, 60000)
    }

    return {
      icons,
      loading,
      username,
      password,
      error,
      invalidUsername,
      invalidPass,
      usFocused,
      mainIcon,
      formState,
      inAudits,
      passwordResetUrl,
      siteName,
      login,
      title,
      loginFormRef,
      passwordRef,
      invalidTwoFA,
      isTwoFAInputVisible,
      twoFA,
      qrCode,
      pin,
      invalidPin,
      isPinVisible,
      TWO_FA_STATUS,
      uri,
      isResendVisible,
      pinTemplateRef,
      showMustLoginAlert,
      showDealerWarning
    }
  },
  i18n: defineComponentTranslations({
    login_to_access_this_feature: {
      en: 'You must be logged in to access this feature',
      el: 'Πρέπει να συνδεθείς για να έχεις πρόσβαση σε αυτή τη λειτουργία'
    },
    login_dealer_warning_title: {
      en: 'A dealer account was found for this email',
      el: "Βρέθηκε εμπορικός λογαριασμός γι' αυτό το email"
    },
    login_dealer_warning_body: {
      en: 'To login use your password',
      el: 'Για να συνδεθείς χρησιμοποίησε τον κωδικό σου'
    }
  })
})
