import { inject } from 'tsyringe'
import { clientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import LoggerService from '~/services/LoggerService'
import { VueI18n, VueRouter } from '~/utils/nuxt3-migration'
import SnackbarService from '~/services/snackbar/SnackbarService'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  LoginResult,
  LogoutResult,
  AuthResult,
  PasswordSetSchemaResult,
  PasswordResetSchemaResult,
  GuestRegisterSchemaResult,
  Agent
} from '~/models/login-register/types'
import CookiesService from '~/services/CookiesService'
import { USER_NS } from '~/store/modules/shared/user/state'
import { StoreWithRootState } from '~/store/types'
import { RecaptchaFormToken } from '~/models/recaptcha/types'
import { removeKeysWithNoValues, toCamelCase } from '~/utils/object'
import { toSnakeCase } from '~/utils/snake-case'
import {
  storeToken,
  vueI18nToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class UserAuthService {
  constructor(
    @inject(LoggerService) private logger: LoggerService,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(SnackbarService) private snackbar: SnackbarService,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(CookiesService) private cookies: CookiesService,
    @inject(storeToken) private store: StoreWithRootState
  ) {}

  @clientOnly
  public async login(
    username: string,
    password: string,
    oneTimePassword?: string,
    retrieveQr?: boolean,
    retrieveQrPin?: string
  ): Promise<LoginResult> {
    let redirectTo
    const currentQuery = this.router?.currentRoute?.query
    const hash = this.router?.currentRoute?.hash
    if (currentQuery?.gotonext || currentQuery?.redirectto) {
      redirectTo = `${currentQuery?.gotonext ||
        currentQuery?.redirectto}${hash}`
    }
    return await this.requestBuilder
      .request('post', '/api/login/')
      .data(
        removeKeysWithNoValues({
          username,
          password,
          redirect_to: redirectTo,
          one_time_password: oneTimePassword,
          retrieve_qr: retrieveQr,
          retrieve_qr_pin: retrieveQrPin
        })
      )
      .send()
  }

  public async getPassResetSchema(
    forAudits: boolean = false
  ): Promise<PasswordResetSchemaResult> {
    const url = forAudits
      ? '/api/audits/password-reset/'
      : '/api/password-reset/'
    return await this.requestBuilder.request('get', url).send()
  }

  @clientOnly
  public async passReset(
    ident: string,
    forAudits: boolean = false,
    recaptchaToken: RecaptchaFormToken
  ): Promise<any> {
    const url = forAudits
      ? '/api/audits/password-reset/'
      : '/api/password-reset/'
    const data: { ident: string; captcha?: RecaptchaFormToken } = { ident }
    data.captcha = recaptchaToken

    return await this.requestBuilder
      .request('post', url)
      .data(data)
      .validate(body => body?.data?.values?.ident === ident)
      .map(body => toCamelCase(body.data.values))
      .send()
  }

  public async getSetPasswordSchema(
    code: string,
    userId: number | string,
    forAudits: boolean = false
  ): Promise<PasswordSetSchemaResult> {
    const url = forAudits
      ? `/api/audits/password-set/${userId}/`
      : `/api/password-set/${userId}/?reset_code=${code}`

    return await this.requestBuilder
      .request('get', url)
      .params({ reset_code: forAudits ? code : undefined })
      .send()
  }

  @clientOnly
  public async setNewPassword(
    code: string,
    userId: number | string,
    password: string,
    forAudits: boolean = false
  ): Promise<AuthResult> {
    const url = forAudits
      ? `/api/audits/password-set/${userId}/`
      : `/api/password-set/${userId}/`

    return await this.requestBuilder
      .request('post', url)
      .data({
        reset_code: code,
        password,
        password_repeat: password
      })
      .send()
  }

  public async getGuestRegisterSchema(): Promise<GuestRegisterSchemaResult> {
    return await this.requestBuilder
      .request('get', '/api/guests/register/')
      .send()
  }

  @clientOnly
  public async registerGuest(
    username: string,
    password: string,
    passwordRepeat: string,
    acceptedTerms: Boolean,
    recaptchaToken: RecaptchaFormToken
  ): Promise<any> {
    let refcode
    if (this.store.getters[`${USER_NS}/isSingleOrAnon`]) {
      refcode = this.store.getters[`${USER_NS}/referenceCode`]
    } else if (this.router?.currentRoute?.query?.refcode) {
      refcode = this.router.currentRoute.query.refcode
    }

    return await this.requestBuilder
      .request('post', '/api/guests/register/')
      .data({
        username,
        password,
        password_repeat: passwordRepeat,
        refcode,
        accepted_terms: acceptedTerms,
        recaptcha_token: recaptchaToken
      })
      .send()
  }

  public async logout(): Promise<LogoutResult | null> {
    try {
      const response = await this.requestBuilder
        .request('post', '/api/logout/')
        .send()
      this.cookies.delete('ss')
      return response
    } catch (error) {
      this.snackbar.error(
        this.i18n.t('an error occurred please try again later') as string
      )
      this.logger.captureError(error)
      return null
    }
  }

  public async guestActivate(
    code: string,
    userId: number | string,
    refcode?: string
  ): Promise<AuthResult> {
    const url = `/api/guests/activate/`
    return await this.requestBuilder
      .request('get', url)
      .params({
        code,
        user_id: userId,
        refcode
      })
      .send()
  }

  public async resendActivationEmail(
    userId: number | string
  ): Promise<AuthResult> {
    return await this.requestBuilder
      .request('post', `/api/resend-activation/${userId}/`)
      .send()
  }

  async fetchAgentRegistrationData() {
    return await this.requestBuilder
      .request('get', `/api/agents/register/`)
      .validate(body => body && body.data.schema)
      .map(body =>
        toCamelCase({ agent: body.data.agent, schema: body.data.schema })
      )
      .send()
  }

  async createAgent(data: Agent) {
    return await this.requestBuilder
      .request('post', `/api/agents/register/`)
      .data(toSnakeCase(data))
      .send()
  }
}
