































































import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { defineComponent, vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  components: { CFormError },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    state: {
      type: Boolean,
      default: null
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'current-password'
    },
    labelClass: {
      type: String,
      default: ''
    },
    formGroupClass: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showPass: false,
      focused: false
    }
  },
  computed: {
    icons() {
      return {
        showPass: faEye,
        hidePass: faEyeSlash,
        lock: faLock
      }
    },
    showHidePassIcon() {
      return this.showPass ? this.icons.hidePass : this.icons.showPass
    }
  },
  methods: {
    resetShowPass() {
      this.showPass = false
    },
    focus() {
      const passwordInput = this.$refs.passwordInput
      passwordInput && passwordInput.focus(true)
    },
    togglePassword() {
      this.showPass = !this.showPass
      this.focus()
    },
    onInput(event: InputEvent) {
      const value = (event.target as HTMLInputElement).value.trim()

      this.$emit('update:modelValue', value)
      this.$emit('input', value)
    }
  }
})
